/****** Font styles ******/
/* Quicksand */
.rc-QuicksandFont {
    font-family: 'Quicksand';
    font-style: normal;

    &.rc-QuicksandH1 {
        font-weight: 300;
        font-size: 96px;
        line-height: 120px;
        letter-spacing: -1.5px;
    }

    &.rc-QuicksandH2 {
        font-weight: 300;
        font-size: 60px;
        line-height: 75px;
    }

    &.rc-QuicksandH3 {
        font-weight: normal;
        font-size: 48px;
        line-height: 60px;
    }

    &.rc-QuicksandH4 {
        font-weight: normal;
        font-size: 34px;
        line-height: 42px;
        letter-spacing: 0.25px;
    }

    &.rc-QuicksandH5 {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
    }

    &.rc-QuicksandH6 {
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.15px;
    }

    &.rc-QuicksandBody1 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.44px;
    }
}

/* Roboto */
.rc-RobotoFont {
    font-family: 'Roboto';
    font-style: normal;

    &.rc-RobotoH1 {
        font-weight: 300px;
        font-size: 96px;
        line-height: 112px;
        letter-spacing: -1.5px;
    }

    &.rc-RobotoH2 {
        font-weight: 300;
        font-size: 60px;
        line-height: 70px;
    }

    &.rc-RobotoH3 {
        font-weight: normal;
        font-size: 48px;
        line-height: 56px;
    }

    &.rc-RobotoH4 {
        font-weight: normal;
        font-size: 34px;
        line-height: 40px;
        letter-spacing: 0.25px;
    }

    &.rc-RobotoH5 {
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
    }

    &.rc-RobotoH6 {
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.15px;
    }

    &.rc-RobotoBody1 {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.44px;
    }

    &.rc-RobotoSubtitle2 {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.1px;
    }

    &.rc-RobotoButton {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.75px;
        text-transform: uppercase;
    }

    &.rc-RobotoBody2 {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.25px;
    }

    &.rc-RobotoBody3 {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.25px;
    }

    &.rc-RobotoCaption {
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.4px;
    }

    &.rc-RobotoOverline {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
}
