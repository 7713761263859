
html, body {
    margin: 0;
    height: 100%;
    overflow-y: hidden;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


  .basic-container {
    padding: 30px;
  }

  .version-info {
    font-size: 8pt;
    float: right;
  }

   

  .mat-cell{
     text-align: center;
   }

   .left-align-cell.mat-cell{
     text-align: left;
   }

   .left-align-header>.mat-sort-header-container{
    justify-content: flex-start !important;
    padding-left:0px !important;
  }
