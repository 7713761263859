/// Foreground and background color set using the primary color 50
@use '@angular/material' as mat;
.rc-colorForegroundPrimary50 {
    color: mat.get-color-from-palette($primary, 50) !important;
}
.rc-colorBackgroundPrimary50 {
    background-color: mat.get-color-from-palette($primary, 50) !important;
}
/// Foreground and background color set using the primary color 100
.rc-colorForegroundPrimary100 {
    color: mat.get-color-from-palette($primary, 100) !important;
}
.rc-colorBackgroundPrimary100 {
    background-color: mat.get-color-from-palette($primary, 100) !important;
}
/// Foreground and background color set using the primary color 200
.rc-colorForegroundPrimary200 {
    color: mat.get-color-from-palette($primary, 200) !important
}
.rc-colorBackgroundPrimary200 {
    background-color: mat.get-color-from-palette($primary, 200) !important
}
/// Foreground and background color set using the primary color 300
.rc-colorForegroundPrimary300 {
    color: mat.get-color-from-palette($primary, 300) !important
}
.rc-colorBackgroundPrimary300 {
    background-color: mat.get-color-from-palette($primary, 300) !important
}
/// Foreground and background color set using the primary color 400
.rc-colorForegroundPrimary400 {
    color: mat.get-color-from-palette($primary, 400) !important
}
.rc-colorBackgroundPrimary400 {
    background-color: mat.get-color-from-palette($primary, 400) !important
}
/// Foreground and background color set using the primary color 500
.rc-colorForegroundPrimary500 {
    color: mat.get-color-from-palette($primary, 500) !important
}
.rc-colorBackgroundPrimary500 {
    background-color: mat.get-color-from-palette($primary, 500) !important
}
/// Foreground and background color set using the primary color 600
.rc-colorForegroundPrimary600 {
    color: mat.get-color-from-palette($primary, 600) !important
}
.rc-colorBackgroundPrimary600 {
    background-color: mat.get-color-from-palette($primary, 600) !important
}
/// Foreground and background color set using the primary color 700
.rc-colorForegroundPrimary700 {
    color: mat.get-color-from-palette($primary, 700) !important
}
.rc-colorBackgroundPrimary700 {
    background-color: mat.get-color-from-palette($primary, 700) !important
}
/// Foreground and background color set using the primary color 800
.rc-colorForegroundPrimary800 {
    color: mat.get-color-from-palette($primary, 800) !important
}
.rc-colorBackgroundPrimary800 {
    background-color: mat.get-color-from-palette($primary, 800) !important
}
/// Foreground and background color set using the primary color 900
.rc-colorForegroundPrimary900 {
    color: mat.get-color-from-palette($primary, 900) !important
}
.rc-colorBackgroundPrimary900 {
    background-color: mat.get-color-from-palette($primary, 900) !important
}
/// Foreground and background color set using the primary color A100
.rc-colorForegroundPrimaryA100 {
    color: mat.get-color-from-palette($primary, A100) !important
}
.rc-colorBackgroundPrimaryA100 {
    background-color: mat.get-color-from-palette($primary, A100) !important
}
/// Foreground and background color set using the primary color A200
.rc-colorForegroundPrimaryA200 {
    color: mat.get-color-from-palette($primary, A200) !important
}
.rc-colorBackgroundPrimaryA200 {
    background-color: mat.get-color-from-palette($primary, A200) !important
}
/// Foreground and background color set using the primary color A400
.rc-colorForegroundPrimaryA400 {
    color: mat.get-color-from-palette($primary, A400) !important
}
.rc-colorBackgroundPrimaryA400 {
    background-color: mat.get-color-from-palette($primary, A400) !important
}
/// Foreground and background color set using the primary color A700
.rc-colorForegroundPrimaryA700 {
    color: mat.get-color-from-palette($primary, A700) !important
}
.rc-colorBackgroundPrimaryA700 {
    background-color: mat.get-color-from-palette($primary, A700) !important
}


/// Foreground and background color set using the warn color 50
.rc-colorForegroundWarn50 {
    color: mat.get-color-from-palette($warn, 50) !important
}
.rc-colorBackgroundWarn50 {
    background-color: mat.get-color-from-palette($warn, 50) !important
}
/// Foreground and background color set using the warn color 100
.rc-colorForegroundWarn100 {
    color: mat.get-color-from-palette($warn, 100) !important
}
.rc-colorBackgroundWarn100 {
    background-color: mat.get-color-from-palette($warn, 100) !important
}
/// Foreground and background color set using the warn color 200
.rc-colorForegroundWarn200 {
    color: mat.get-color-from-palette($warn, 200) !important
}
.rc-colorBackgroundWarn200 {
    background-color: mat.get-color-from-palette($warn, 200) !important
}
/// Foreground and background color set using the warn color 300
.rc-colorForegroundWarn300 {
    color: mat.get-color-from-palette($warn, 300) !important
}
.rc-colorBackgroundWarn300 {
    background-color: mat.get-color-from-palette($warn, 300) !important
}
/// Foreground and background color set using the warn color 400
.rc-colorForegroundWarn400 {
    color: mat.get-color-from-palette($warn, 400) !important
}
.rc-colorBackgroundWarn400 {
    background-color: mat.get-color-from-palette($warn, 400) !important
}
/// Foreground and background color set using the warn color 500
.rc-colorForegroundWarn500 {
    color: mat.get-color-from-palette($warn, 500) !important
}
.rc-colorBackgroundWarn500 {
    background-color: mat.get-color-from-palette($warn, 500) !important
}
/// Foreground and background color set using the warn color 600
.rc-colorForegroundWarn600 {
    color: mat.get-color-from-palette($warn, 600) !important
}
.rc-colorBackgroundWarn600 {
    background-color: mat.get-color-from-palette($warn, 600) !important
}
/// Foreground and background color set using the warn color 700
.rc-colorForegroundWarn700 {
    color: mat.get-color-from-palette($warn, 700) !important
}
.rc-colorBackgroundWarn700 {
    background-color: mat.get-color-from-palette($warn, 700) !important
}
/// Foreground and background color set using the warn color 800
.rc-colorForegroundWarn800 {
    color: mat.get-color-from-palette($warn, 800) !important
}
.rc-colorBackgroundWarn800 {
    background-color: mat.get-color-from-palette($warn, 800) !important
}
/// Foreground and background color set using the warn color 900
.rc-colorForegroundWarn900 {
    color: mat.get-color-from-palette($warn, 900) !important
}
.rc-colorBackgroundWarn900 {
    background-color: mat.get-color-from-palette($warn, 900) !important
}
/// Foreground and background color set using the warn color A100
.rc-colorForegroundWarnA100 {
    color: mat.get-color-from-palette($warn, A100) !important
}
.rc-colorBackgroundWarnA100 {
    background-color: mat.get-color-from-palette($warn, A100) !important
}
/// Foreground and background color set using the warn color A200
.rc-colorForegroundWarnA200 {
    color: mat.get-color-from-palette($warn, A200) !important
}
.rc-colorBackgroundWarnA200 {
    background-color: mat.get-color-from-palette($warn, A200) !important
}
/// Foreground and background color set using the warn color A400
.rc-colorForegroundWarnA400 {
    color: mat.get-color-from-palette($warn, A400) !important
}
.rc-colorBackgroundWarnA400 {
    background-color: mat.get-color-from-palette($warn, A400) !important
}
/// Foreground and background color set using the warn color A700
.rc-colorForegroundWarnA700 {
    color: mat.get-color-from-palette($warn, A700) !important
}
.rc-colorBackgroundWarnA700 {
    background-color: mat.get-color-from-palette($warn, A700) !important
}


/// Foreground and background color set using the neutral color 50
.rc-colorForegroundNeutral50 {
    color: mat.get-color-from-palette($rc-neutral, 50) !important;
}
.rc-colorBackgroundNeutral50 {
    background-color: mat.get-color-from-palette($rc-neutral, 50) !important;
}
/// Foreground and background color set using the neutral color 100
.rc-colorForegroundNeutral100 {
    color: mat.get-color-from-palette($rc-neutral, 100) !important
}
.rc-colorBackgroundNeutral100 {
    background-color: mat.get-color-from-palette($rc-neutral, 100) !important
}
/// Foreground and background color set using the neutral color 200
.rc-colorForegroundNeutral200 {
    color: mat.get-color-from-palette($rc-neutral, 200) !important
}
.rc-colorBackgroundNeutral200 {
    background-color: mat.get-color-from-palette($rc-neutral, 200) !important
}
/// Foreground and background color set using the neutral color 300
.rc-colorForegroundNeutral300 {
    color: mat.get-color-from-palette($rc-neutral, 300) !important
}
.rc-colorBackgroundNeutral300 {
    background-color: mat.get-color-from-palette($rc-neutral, 300) !important
}
/// Foreground and background color set using the neutral color 400
.rc-colorForegroundNeutral400 {
    color: mat.get-color-from-palette($rc-neutral, 400) !important
}
.rc-colorBackgroundNeutral400 {
    background-color: mat.get-color-from-palette($rc-neutral, 400) !important
}
/// Foreground and background color set using the neutral color 500
.rc-colorForegroundNeutral500 {
    color: mat.get-color-from-palette($rc-neutral, 500) !important
}
.rc-colorBackgroundNeutral500 {
    background-color: mat.get-color-from-palette($rc-neutral, 500) !important
}
/// Foreground and background color set using the neutral color 600
.rc-colorForegroundNeutral600 {
    color: mat.get-color-from-palette($rc-neutral, 600) !important
}
.rc-colorBackgroundNeutral600 {
    background-color: mat.get-color-from-palette($rc-neutral, 600) !important
}
/// Foreground and background color set using the neutral color 700
.rc-colorForegroundNeutral700 {
    color: mat.get-color-from-palette($rc-neutral, 700) !important
}
.rc-colorBackgroundNeutral700 {
    background-color: mat.get-color-from-palette($rc-neutral, 700) !important
}
/// Foreground and background color set using the neutral color 800
.rc-colorForegroundNeutral800 {
    color: mat.get-color-from-palette($rc-neutral, 800) !important
}
.rc-colorBackgroundNeutral800 {
    background-color: mat.get-color-from-palette($rc-neutral, 800) !important
}
/// Foreground and background color set using the neutral color 900
.rc-colorForegroundNeutral900 {
    color: mat.get-color-from-palette($rc-neutral, 900) !important
}
.rc-colorBackgroundNeutral900 {
    background-color: mat.get-color-from-palette($rc-neutral, 900) !important
}
/// Foreground and background color set using the neutral color A100
.rc-colorForegroundNeutralA100 {
    color: mat.get-color-from-palette($rc-neutral, A100) !important
}
.rc-colorBackgroundNeutralA100 {
    background-color: mat.get-color-from-palette($rc-neutral, A100) !important
}
/// Foreground and background color set using the neutral color A200
.rc-colorForegroundNeutralA200 {
    color: mat.get-color-from-palette($rc-neutral, A200) !important
}
.rc-colorBackgroundNeutralA200 {
    background-color: mat.get-color-from-palette($rc-neutral, A200) !important
}

/// Basic background color
.rc-warnBackgroundColor {
    background-color: mat.get-color-from-palette($warn) !important;
}

.rc-primaryBackgroundColor {
    background-color: mat.get-color-from-palette($primary) !important;
}

.rc-backgroundColor {
    background-color: $custom-background-color !important;
}

.rc-pageBackgroundColor {
    background-color: $custom-page-background-color !important;
}

.rc-accentBackgroundColor {
    background-color: $accentColor;
}
.rc-accentForegroundColor {
    color: $accentColor;
}

.rc-secondaryComponentBackgroundColor {
    background-color: $secondaryBackgroundColor !important;
}

.rc-backgroundSuccess {
    background-color: $backgroundSuccess !important;
}

.rc-backgroundIconColor {
    background-color: $iconColor !important;
}

/// Condition background color
.rc-backgroundColorHover {
    background-color: $backgroundColorHover !important;
}

/// Border color
.rc-borderPrimaryColor {
    border-color: mat.get-color-from-palette($primary, 500) !important;
}
.rc-borderColorPrimary700 {
    border-color: mat.get-color-from-palette($primary, 700) !important;
}
.rc-borderColorPrimary100 {
    border-color: mat.get-color-from-palette($primary, 100) !important;
}
.rc-borderColorPrimary300 {
    border-color: mat.get-color-from-palette($primary, 300) !important;
}
.rc-borderWarnColor {
    border-color: mat.get-color-from-palette($warn, 500) !important;
}
.rc-borderColorNeutral50 {
    border-color: mat.get-color-from-palette($rc-neutral, 50) !important;
}
.rc-borderColorNeutral400 {
    border-color: mat.get-color-from-palette($rc-neutral, 400) !important;
}
.rc-borderColorNeutral500 {
    border-color: mat.get-color-from-palette($rc-neutral, 400) !important;
}
.rc-borderColorNeutral600 {
    border-color: mat.get-color-from-palette($rc-neutral, 600) !important;
}
.rc-borderColorNeutral700 {
    border-color: mat.get-color-from-palette($rc-neutral, 700) !important;
}
.rc-borderColorNeutral800 {
    border-color: mat.get-color-from-palette($rc-neutral, 800) !important;
}
.rc-borderColorNeutralA100 {
    border-color: mat.get-color-from-palette($rc-neutral, A100) !important;
}

.rc-borderColorIconColor {
    border-color: $iconColor !important;
}

/// Icons fill color
.rc-fillNeutral50 {
    svg {
        fill: mat.get-color-from-palette($rc-neutral, 50) !important;
    }
}

.rc-fillNeutral600 {
    svg {
        fill: mat.get-color-from-palette($rc-neutral, 600) !important;
    }
}

.rc-fillPrimary500 {
    svg {
        fill: mat.get-color-from-palette($primary, 500) !important;
    }
}

.rc-fillNeutral300 {
    svg {
        fill: mat.get-color-from-palette($rc-neutral, 300) !important;
    }
}

.rc-fillPrimary100 {
    svg {
        fill: mat.get-color-from-palette($primary, 100) !important;
    }
}

.rc-fillWarn500 {
    svg {
        fill: mat.get-color-from-palette($warn, 500) !important;
    }
}

.rc-fillAccentColor {
    svg {
        fill: $accentColor !important;
    }
}

.rc-fillIconColor {
    svg {
        fill: $iconColor !important;
    }
}

.rc-fillIconColorDisabled {
    svg {
        fill: $iconColorDisabled !important;
    }
}

.rc-fillIconColorDisabled2 {
    svg {
        fill: $iconColorDisabled2 !important;
    }
}

/// Icon color (foreground color for material icon)
.rc-iconColor {
    color: $iconColor !important;
}


/// Icon stroke color
.rc-strokeNeutral50 {
    svg {
        stroke: mat.get-color-from-palette($rc-neutral, 50) !important;
    }
}

/// Checkbox frame (square) color
.rc-checkboxFrameBorderColorPrimary500 {
    .mat-checkbox-frame {
        border-color: mat.get-color-from-palette($primary, 500);
    }
}
