@use '@angular/material' as mat;
.mat-drawer .mat-drawer-push {
    @extend .rc-backgroundColor;
}

.mat-list-base .mat-list-item {
    @extend .rc-RobotoFont;
    @extend .rc-RobotoBody3;
    @extend .rc-colorForegroundNeutral50;
}

.container > div {
    word-break: break-word;
}

.mat-drawer-backdrop.mat-drawer-shown {
    @extend .rc-colorBackgroundNeutral900;
    opacity: 0.6;
    mix-blend-mode: normal;
}

.mat-checkbox-checked.mat-accent {
    .mat-checkbox-background {
        @extend .rc-colorBackgroundNeutral50;
    }
}

mat-sidenav {
  min-width: 60px;
  height: calc(100vh - 64px);

    .mat-drawer-inner-container:hover {
        overflow: auto;
    }

    .mat-drawer-inner-container {
        @extend .rc-colorBackgroundNeutral500;
        overflow: hidden;
    }

    .mat-menu-item{
      @extend .rc-colorForegroundNeutral50;
    }

    .mat-icon-no-color{
      @extend .rc-colorForegroundNeutral50;
    }
}

mat-sidenav-container{
      height: 100%;
}


rc-sidenav {
    .mat-list-base {
        padding-top: 0px;
    }

    .rc-sidenavButton {
        .mat-button-focus-overlay {
            background: red;
        }
    }
}

.mat-toolbar{
    @extend .rc-colorForegroundNeutral50;
}

@keyframes expand {
    from { height: 0px;}
    to { height: 48px;}
}

.rc-topbarNavLink {
    @extend .rc-colorBackgroundPrimary500 ;
}




/** TABLES **/
.rc-table {
  width: 100%;
  background-color: $custom-background-color;
  td, th {
  width:auto;
  text-align: left;
  }

  .mat-sort-header-container{
    justify-content: left;
  }

}

.mat-paginator {
  //background-color: $rc-background-color !important;
}

.rc-container-table{
  margin: 0 auto;
  width:100%;

}

.rc-container-app{
margin: 0 auto;
padding:1%;

}

/** END TABLES **/

.mat-dialog-container{
  background-color: $custom-page-background-color;
}

.rc-tooltip {
    @extend .rc-RobotoFont;
    @extend .rc-RobotoButton;
    @extend .rc-colorForegroundNeutral300;
}

.rc-lightThemeWhiteText {
    @extend .rc-colorForegroundNeutral50;
}

.rc-lightThemeTextColorDark {
    @extend .rc-colorForegroundNeutral800;
}

.mat-paginator {
    .mat-select-arrow {
        @extend .rc-colorForegroundNeutral800;
    }

    .mat-paginator-page-size-select {
        .mat-form-field-underline {
            @extend .rc-colorForegroundNeutral800;
        }
    }

    .mat-paginator-icon {
        fill: mat.get-color-from-palette($rc-neutral, 800);
    }

}/*
rc-menulistitem {

    .mat-list-item-content{
      color: darkgray;
      .mat-icon{
        color: darkgray !important  ;
      }
    }
    .mat-list-item:focus > .mat-list-item-content {
      color: white;
      .mat-icon{
        color: white  !important  ;
      }
    }
    .mat-list-item:active > .mat-list-item-content {
      color: white;
      .mat-icon{
        color: white  !important  ;
      }
    }


    .mat-list-item:hover > .mat-list-item-content {
      color: white;
      .mat-icon{
        color: white  !important  ;
      }
    }
    */
rc-menulistitem {
    .mat-list-item-content{
        color: gray;
        .mat-icon{
          color: gray !important  ;
        }
      }

    .rc-menu-list-item.active  {
        background-color:  darkgray;

    }

    .rc-menu-list-item.active  > .mat-list-item-content {

        color: white;
    .mat-icon{
      color: white  !important  ;
    }

    }
    .rc-menu-list-item:hover  > .mat-list-item-content {

        color: white;
    .mat-icon{
      color: white  !important  ;
    }

    }


    }
.rc-breadcrumb {

  font-variant: small-caps;
  width: auto;

  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  position: relative;
  margin-left: -20px;
  margin-top: -20px;
  a {


    color:  mat.get-color-from-palette($rc-neutral, 800) !important;
    text-decoration: none;
  }
  /* Add a color on mouse-over */
  a:hover {
  color:mat.get-color-from-palette($rc-neutral, 700) !important;
  text-decoration: none;
}
}
