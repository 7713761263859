@use '@angular/material' as mat;
.mat-raised-button, .mat-fab, .mat-mini-fab {
    &.mat-primary:hover:not(:disabled) {
        background: mat.get-color-from-palette($primary, A700);
    }

    &.mat-primary:disabled {
        background: mat.get-color-from-palette($primary, 100);
        opacity: 0.48;
        color: #c4fff0;
    }

    &.lightPrimaryBg {
        background: mat.get-color-from-palette($primary, lighter);

    &.lightPrimaryBg:hover {
            background: mat.get-color-from-palette($primary, A700);
        }
    }

    &.mat-warn:hover:not(:disabled) {
        background: mat.get-color-from-palette($warn, A700);
    }

    &.mat-warn:disabled {
        background: mat.get-color-from-palette($primary, lighter);
        opacity: 0.48;
        color: #ff6281bd;
    }

    &.lightWarnBg {
        background: mat.get-color-from-palette($warn, 50);
        color: mat.get-color-from-palette($warn);
    }

    &.userFabBtn {
        background: #EB00FF;
    }

}
