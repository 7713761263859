@use '@angular/material' as mat;
.mat-drawer .mat-drawer-push {
    @extend .rc-secondaryComponentBackgroundColor;
}

.mat-list-base .mat-list-item {
    @extend .rc-RobotoFont;
    @extend .rc-RobotoBody2;
    @extend .rc-colorForegroundNeutral50;
}

.container > div {
    word-break: break-word;
}

.mat-drawer-backdrop.mat-drawer-shown {
    @extend .rc-colorBackgroundNeutral900;
    opacity: 0.6;
    mix-blend-mode: normal;
}

.mat-checkbox-checked.mat-accent {
    .mat-checkbox-background {
        @extend .rc-colorBackgroundNeutral50;
    }
}

mat-sidenav {
  min-width: 60px;
  height: calc(100vh - 64px);

    .mat-drawer-inner-container:hover {
        overflow: auto;
    }

    .mat-drawer-inner-container {
        @extend .rc-secondaryComponentBackgroundColor;
        overflow: hidden;
    }
}

rc-sidenav {
    .mat-list-base {
        padding-top: 0px;
    }

    .rc-sidenavButton {
        .mat-button-focus-overlay {
            background: $custom-page-background-color;
        }
    }
}

@keyframes expand {
    from { height: 0px;}
    to { height: 48px;}
}


mat-sidenav-container {
    height: 100%;
}

.rc-topbarNavLink {
    @extend .rc-colorForegroundNeutral300;
    @extend .rc-colorBackgroundNeutral800;
}


.rc-tooltip {
    @extend .rc-RobotoFont;
    @extend .rc-RobotoButton;
    @extend .rc-colorForegroundNeutral300;
}

.mat-row:hover {
  cursor: default;
  }

  .rc-table {
    background-color: $rc-background-color;
  width: 100%;
    td, th {
    width:auto;
    text-align: left;
    }

    .mat-sort-header-container{
      justify-content: left;
    }

  }

  .mat-paginator {
    background-color: $rc-background-color !important;
  }

  .rc-container-table{
    margin: 0 auto;
    width:100%;

  }

  .rc-container-app{
  margin: 0 auto;
  padding:1%;

  }


  .mat-form-field {
  font-size: 14px;
  width: 100%;
  }


  .mat-menu-panel{
    background-color: $rc-background-color;
    @extend .rc-colorForegroundNeutral50;
  }

.mat-select-panel{
  background-color: $rc-background-color;
}

.mat-dialog-container{
  background-color: $rc-page-background-color;
  @extend .rc-colorForegroundNeutral50;
}



  .container-table{
    margin: 0 auto;
    width:100%;

  }

  ngx-daterangepicker-material {
    @extend .rc-colorForegroundNeutral50;
    .md-drppicker.double, .calendar-table {
        @extend .rc-colorBackgroundNeutral800;
    }

    .md-drppicker {
        @extend .rc-colorBackgroundNeutral800;

        .calendar-table {
            border-left: 1px solid !important;
            border-left-color: mat.get-color-from-palette($rc-neutral, 50) !important;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    td.in-range:not(.end-date):not(.off) {
        @extend .rc-borderColorNeutral800;
        @extend .rc-colorBackgroundNeutral700;
        @extend .rc-colorForegroundNeutral50;
    }

    .table-condensed td {
        @extend .rc-RobotoFont;
        @extend .rc-RobotoBody2;
    }

    td.start-date, td.end-date {
        @extend .rc-backgroundSuccess;
    }

    td.off {
        @extend .rc-colorBackgroundNeutral800;
    }

    div.dropdowns, .ranges button {
        @extend .rc-colorForegroundNeutral50;
    }

    .ranges > ul > li > button.active {
        @extend .rc-backgroundSuccess;
    }

}
.rc-menu-list-item.active {
  background-color: $backgroundColorHover;
}
&:hover,
&:focus {
  >.rc-menu-list-item:not(.expanded) {
    background-color: $backgroundColorHover !important;
  }
}

.rc-breadcrumb {
 
  font-variant: small-caps;
  width: auto;
  
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
 
  position: relative;
  margin-left: -20px;
  margin-top: -20px;
  a {


    color:  mat.get-color-from-palette($rc-neutral, 200) !important;
    text-decoration: none;
  }
  /* Add a color on mouse-over */
  a:hover {
  color:mat.get-color-from-palette($rc-neutral, 300) !important;
  text-decoration: none;
}
}
 
