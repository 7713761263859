@use '@angular/material' as mat;
@import '@angular/material/theming';

@include mat.core();

$whitePalette: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #ffffff,
        100 : #ffffff,
        200 : #ffffff,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$rc-neutral: (
    50 : #ffffff,
    100 : #F5F8F8,
    200 : #E3E5E6,
    300 : #C6CACC,
    400 : #919699,
    500 : #73787A,
    600 : #5E6366,
    700 : #3E4345,
    800 : #0C0E0E,
    900 : rgba(0, 0, 0, 0.33),
    A100: rgba(255, 255, 255, 0.48),
    A200: #C4C4C4
);

$primary: mat.define-palette($whitePalette);
$accent: mat.define-palette($whitePalette);
$warn: mat.define-palette($whitePalette);

$custom-page-background-color: #ffffff;
$custom-background-color: #ffffff;

/**************************
DARK THEME
**************************/
.dark-theme {
    $rc-primary: (
        50 : #CAE9E1,
        100 : #97BBB2,
        200 : #80c8b6,
        300 : #4db199,
        400 : #27a183,
        500 : #01906d,
        600 : #018865,
        700 : #017d5a,
        800 : #017350,
        900 : #00613e,
        A100 : #91ffd0,
        A200 : #5effba,
        A400 : #2bffa4,
        A700 : #01AE84,
        contrast: (
            50 : #000000,
            100 : #000000,
            200 : #000000,
            300 : #000000,
            400 : #ffffff,
            500 : #ffffff,
            600 : #ffffff,
            700 : #ffffff,
            800 : #ffffff,
            900 : #ffffff,
            A100 : #000000,
            A200 : #000000,
            A400 : #000000,
            A700 : #000000,
        )
    );

    $rc-warn: (
        50 : #fee4e2,
        100 : #f8b3c0,
        200 : #f38096,
        300 : #ee4d6c,
        400 : #ea264d,
        500 : #e6002d,
        600 : #e30028,
        700 : #df0022,
        800 : #db001c,
        900 : #d50011,
        A100 : #fffcfd,
        A200 : #ffc9cb,
        A400 : #ff969a,
        A700 : #ff7d81,
        contrast: (
            50 : #000000,
            100 : #000000,
            200 : #000000,
            300 : #000000,
            400 : #ffffff,
            500 : #ffffff,
            600 : #ffffff,
            700 : #ffffff,
            800 : #ffffff,
            900 : #ffffff,
            A100 : #000000,
            A200 : #000000,
            A400 : #000000,
            A700 : #000000,
        )
    );

    $rc-neutral: (
        50 : #ffffff,
        100 : #F5F8F8,
        200 : #E3E5E6,
        300 : #C6CACC,
        400 : #919699,
        500 : #73787A,
        600 : #5E6366,
        700 : #3E4345,
        800 : #0C0E0E,
        900 : rgba(0, 0, 0, 0.33),
        A100: rgba(255, 255, 255, 0.48),
        A200: #C4C4C4
    );

    $rc-page-background-color: #5C6D68;
    $rc-background-color: #2B3A35;
    $secondaryBackgroundColor: #11221D;
    $backgroundColorHover: #2B3A35;
    $backgroundSuccess: #3B8858;
    $iconColor: #708681;
    $iconColorDisabled: #6dffda;
    $iconColorDisabled2: #4c9c88;

    $primary: mat.define-palette($rc-primary);
    $accent:  mat.define-palette($rc-primary, A200, A100, A400);
    $warn: mat.define-palette($rc-warn);

    $themeDark: mat.define-dark-theme($primary, $accent, $warn);

    $background: map-get($themeDark, background);
    $background: map_merge($background, (background: $rc-page-background-color));
    $rc-themeDark: map_merge($themeDark, (background: $background));

    @include mat.all-component-themes($rc-themeDark);

    $accentColor: #EB9E05;//#F3AA18

    @import 'commons/color';
    @import 'darkstyle/buttons';
    @import 'darkstyle/components';
 //   @import 'darkstyle/tags';
 //   @import 'darkstyle/textfields';
    @import 'commons/font';
}

/**********************
LIGHT THEME
**********************/
.light-theme {
    $rc-primary: (
        50 : #97BBB2,
        100 : #97BBB2,
        200 : #80c8b6,
        300 : #4db199,
        400 : #27a183,
        500 : #01906d,
        600 : #018865,
        700 : #017d5a,
        800 : #017350,
        900 : #00613e,
        A100 : #91ffd0,
        A200 : #5effba,
        A400 : #2bffa4,
        A700 : #01AE84,
        contrast: (
            50 : #000000,
            100 : #000000,
            200 : #000000,
            300 : #000000,
            400 : #ffffff,
            500 : #ffffff,
            600 : #ffffff,
            700 : #ffffff,
            800 : #ffffff,
            900 : #ffffff,
            A100 : #000000,
            A200 : #000000,
            A400 : #000000,
            A700 : #000000,
        )
    );

    $rc-warn: (
        50 : #fee4e2,
        100 : #f8b3c0,
        200 : #f38096,
        300 : #ee4d6c,
        400 : #ea264d,
        500 : #e6002d,
        600 : #e30028,
        700 : #df0022,
        800 : #db001c,
        900 : #d50011,
        A100 : #fffcfd,
        A200 : #ffc9cb,
        A400 : #ff969a,
        A700 : #ff7d81,
        contrast: (
            50 : #000000,
            100 : #000000,
            200 : #000000,
            300 : #000000,
            400 : #ffffff,
            500 : #ffffff,
            600 : #ffffff,
            700 : #ffffff,
            800 : #ffffff,
            900 : #ffffff,
            A100 : #000000,
            A200 : #000000,
            A400 : #000000,
            A700 : #000000,
        )
    );

    $rc-neutral: (
        50 : #ffffff,
        100 : #F5F8F8,
        200 : #E3E5E6,
        300 : #C6CACC,
        400 : #919699,
        500 : #73787A,
        600 : #5E6366,
        700 : #3E4345,
        800 : #0C0E0E,
        900 : rgba(0, 0, 0, 0.33),
        A100: rgba(255, 255, 255, 0.48),
        A200: #C4C4C4
    );

    $custom-page-background-color: #E3E5E6;
    $custom-background-color: #ffffff;
    $secondaryBackgroundColor: #11221D;
    $backgroundColorHover: #2B3A35;
    $backgroundSuccess: rgb(236, 14, 70);
    $iconColor: #708681;
    $iconColorDisabled: #6dffda;
    $iconColorDisabled2: #4c9c88;

    $primary: mat.define-palette($rc-primary);
    $accent:  mat.define-palette($rc-primary, A200, A100, A400);
    $warn: mat.define-palette($rc-warn);

    $themeLight: mat.define-light-theme($primary, $accent, $warn);

    $background: map-get($themeLight, background);
    $background: map_merge($background, (background: $custom-page-background-color));
    $rc-themeLight: map_merge($themeLight, (background: $background));

    @include mat.all-component-themes($rc-themeLight);

    $accentColor: #EB9E05;//#F3AA18;

    @import 'commons/color';
    @import 'lightstyle/buttons';
    @import 'lightstyle/components';
//    @import 'lightstyle/tags';
    @import 'commons/font';
}

@import 'commons/font';


html, body { height: 100%;}
body {
    @extend .rc-pageBackgroundColor;
    margin: 0px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
